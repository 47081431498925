<template>
    <rsck-page :padding="0">
        <tab @tab-switch="tabSwitch" :is-show-line="false">
            <nut-tab-panel :tab-title="item.label" v-for="(item,index) in catalog" :key="index">
                <rsck-refresh v-if="showResult" :ref="'refresh'+'_'+index" :id="'container'+'_'+index"
                              :height="scrollHeight"
                              @onPullDown="onPullDown" @onPullUp="onPullUp" :style="{top:tabHeight+'px'}"
                              :space-height="0" padding="0">
                    <div class="gift-container" v-for="(record,recordIndex) in dataList[index]" :key="record.id">
                        <div class="title">
                            <div class="title-left">
                                {{record.store_name}}
                            </div>
                            <div class="title-right">
                                {{record.gift_name}}
                            </div>
                        </div>
                        <div class="info">
                            <div class="info-left">
                                <div class="turntable-name">
                                    {{record.turntable_name}}
                                </div>
                                <div class="gift-get-time">
                                    抽奖时间：{{record.create_time}}
                                </div>
                                <div class="gift-get-time" v-if="record.status == 1">
                                    核销时间：{{record.use_time}}
                                </div>
                            </div>
                            <div class="info-right" v-if="record.status == 2" @click="showCode(record)">
                                <div class="button">
                                    核销码
                                </div>
                            </div>
                        </div>
                    </div>
                </rsck-refresh>
            </nut-tab-panel>
        </tab>
        <nut-popup
                v-model="show"
                position="center"
                :closeable="false"
                :round="true"
        >
            <div class="popup-container">
                <div class="title">请商家扫码核销</div>
                <div class="codes">
                    <img :src="recordCodes"/>
                </div>
                <div class="button-container" @click="show = false">
                    确定
                </div>
            </div>

        </nut-popup>
    </rsck-page>
</template>

<script>
  import RsckPage from '@/components/common/rsck-page'
  import Tab from '@/components/tab/Tab'
  import RsckRefresh from '@/components/common/rsck-refresh'

  export default {
    name: 'gift-log',
    components: {RsckRefresh, Tab, RsckPage},
    mounted () {
      document.title = '中奖记录'
      let query = this.$route.query
      this.user_id = query?.user_id || 0
      this.token = query?.token || ''

      setTimeout(() => {
        this.tabHeight = document.querySelector('.nut-tab-title').clientHeight
        this.scrollHeight = window.innerHeight - this.tabHeight
        this.init()
      }, 200)
    },
    data () {
      return {
        user_id: 0,
        token: '',
        showIndex: 0,
        dataList: [
          [],
          []
        ],
        page: [
          1,
          1
        ],
        pageSize: [
          10,
          10
        ],
        catalog: [
          {label: '未领取', value: 2},
          {label: '已领取', value: 1}
        ],
        scrollHeight: 0,
        tabHeight: 0,
        hasMore: [
          true,
          true
        ],
        showResult: false,
        show: false,
        recordCodes: ''
      }
    },
    methods: {
      init () {
        this.showIndex = 0
        this.dataList = [[], []]
        this.page = [1, 1]
        this.pageSize = [10, 10]
        this.hasMore = [true, true]
        this.getGiftLog().then(() => {
          this.showResult = true
          //取消上拉
          //取消下拉
        })
      },
      getGiftLog () {
        return new Promise((resolve, reject) => {
          let params = {
            page: this.page[this.showIndex],
            pagesize: this.pageSize[this.showIndex],
            user_id: this.user_id,
            status: this.catalog[this.showIndex].value
          }
          return this.$api.actives.getGiftLog(params).then(response => {
            let data = response.data
            if (data.length < this.pageSize[this.showIndex]) {
              this.hasMore[this.showIndex] = false
            }

            this.page[this.showIndex]++
            data.forEach(item => {
              this.dataList[this.showIndex].push(item)
            })

            resolve()
          }).catch(error => {
            reject(error)
          })
        })
      },
      onPullDown () {
        //下拉刷新
        this.page[this.showIndex] = 1
        this.dataList[this.showIndex] = []
        this.hasMore[this.showIndex] = true
        this.getGiftLog().then(() => {
          let refreshName = 'refresh_' + this.showIndex
          this.$refs[refreshName][0].endPullDown()
          this.$forceUpdate()
        })

      },
      onPullUp () {
        this.getGiftLog().then(() => {
          let refreshName = 'refresh_' + this.showIndex
          this.$refs[refreshName][0].endPullUp(!this.hasMore[this.showIndex])
          this.$forceUpdate()
        })
      },
      tabSwitch (e) {
        this.showIndex = e
        if(this.dataList[this.showIndex].length == 0){
          this.getGiftLog()
        }
      },
      showCode (record) {
        this.recordCodes = record.code_path
        this.show = true
      }
    }
  }
</script>

<style scoped lang="scss">
    /deep/ .nut-tab {
        padding: 0;
        border: none;
    }

    /deep/ .nut-tab-title {
        border-bottom: none;
        position: sticky !important;
        top: 0;
    }

    /deep/ .nut-tab-item {
        background: #f6f6f6;
        border: none;
        padding: 0;
    }

    /deep/ .nut-tab-active a {
        color: #F46A17;
    }

    .gift-container {
        background: #fff;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        padding: 15px;
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;

        .title {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 0 0 15px 0;
            border-bottom: #EBEBEB solid 1px;
            margin-bottom: 15px;

            .title-left {
                flex: 1;
                color: #333333;
                font-size: 14px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: bold;
            }

            .title-right {
                flex: 1;
                color: #C10000;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                display: flex;
                justify-content: flex-end;
            }
        }

        .info {
            width: 100%;
            display: flex;

            .info-left {
                flex: 1;
                display: flex;
                flex-wrap: wrap;

                .turntable-name {
                    width: 100%;
                    font-size: 14px;
                    color: #333333;
                }

                .gift-get-time {
                    width: 100%;
                    color: #939393;
                    font-size: 12px;
                    margin-top: 10px;
                }
            }

            .info-right {
                width: 70px;

                .button {
                    width: 100%;
                    height: 36px;
                    -webkit-border-radius: 36px;
                    -moz-border-radius: 36px;
                    border-radius: 36px;
                    border: solid 1px #F46A17;
                    color: #F46A17;
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    /deep/ .popup-box {
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
    }

    .popup-container {
        width: 291px;
        padding: 22px;

        .title {
            width: 100%;
            font-size: 16px;
            color: #333333;
            font-weight: bold;
        }

        .codes {
            width: 100%;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .button-container {
            width: 100%;
            background: #FFECE1;
            height: 44px;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            border-radius: 6px;
            color: #F46A17;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
</style>